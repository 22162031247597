@media (min-width: 100px) {
.jumbotron-fluid {
    background-color: transparent !important;
    margin-top: 40px !important;
   
      
}

.youtube-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #FCEFF1;
  margin: 5px;
  box-shadow: 1rem 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-player img {
  object-fit: cover;
  display: block;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.sidebar {
  min-height: 100vh;
  background-color: #FFFFFF;
}

.navbar-dashboard {
  background-color: #FFFFFF;
}

.dashboard-body {
  background-color: #F4F5F7;
}

.gamesButton {
  background-color:  #E04F60  !important;     
  border-color: transparent !important;
  
}

.gamesCards {
  max-height: 20vw !important;
}

.gamesCardsTemp {
  max-height: 20vw !important;
}

.gamesCards:hover {
  cursor: pointer;
}

.blogCardsDashboard:hover {
  cursor: pointer;
}

.iconEngagementHelp:hover {
  cursor: pointer;
}


.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url('//i.imgur.com/TxzC70f.png') no-repeat;
  cursor: pointer;
} 



body {
  background-color: #ffffff !important;
}

.logos-bg{
  background-color: #E04F60 !important;
}





.cheese-bg {
  /* background-image: url(./config/ricotta-bg-2.png); */
  background-color: #FCEFF1 !important;
}



.billing-bg {
    background-image: url(./config/Ricotta_Billing_2.jpg);
    background-size: cover;
  background-position: center center;
  min-height: 100vh

  /* background-color: #FCEFF1 !important; */
}

.img-trivia {
  box-shadow: 0.5rem 0.5rem 1rem 0.5rem rgba(0, 0, 0, 0.1);
}

.trivia-header {
  /* The image used */
  /* background-image: url(./config/Ricotta_background3.png); */

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.HomePage-typewriter {
  display: flex;
}

.container.wide {
  width: 9rem;
}

.footer {
  background-color: #FCEFF1 !important;
}

.ricotta-color {
  color:#E04F60 !important; 
  /* background-color: #FBE158 !important; */
}

.footer-link { color: #8a8787;
  text-decoration: none;
} /* CSS link color (red) */
.footer-link:hover { color:#615f5f; 
  text-decoration: none;} /* CSS link hover (green) */

/* .footer-link {
  opacity: .5;
  transition: opacity .2s ease;
  color: #4f5c65;
  font-size: 14px;
  line-height: 18px;
} */



.sign-up {
    
    background-color:  transparent !important;  
    border-color: gray !important;
    color:#E04F60 !important; 
}

.navbar-btn {
  margin-top: 5px;
  border-color: transparent !important;
  background-color:  #E04F60 !important; 
  color: #ffffff !important;
}

.footer-btn {
  margin-top: 3px;
  border-color: transparent !important;
  background-color:  #E04F60 !important; 
  color: #ffffff !important;
}

.modalHeader {
  color:#E04F60 !important; 
}

.Navbar {
    background-color: #ffffff !important;
}

.promo {
  background-color: #e87b88 !important;
  /* border:3px solid #E04F60 !important; */
  height: 42px;
}

.promo-button {
  border-color: transparent !important;
}



.navbar-t {
  color:  #ffffff !important;
}

.navbar-center {
    display: inline-block !important;
    text-align: center !important;
}

.signUpForm {
     margin-top: 20px !important;
    height: 60px !important;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.featureHeading {
       color:#E04F60;  
       margin-top: 20px;
}    

.social {
         text-align: center;
}

.dash-buttons {
  display: block;
  width: 100%;
  margin-bottom: 0.2rem;
}



small {
   display: block; 
   margin-top: 5px;
   text-align: center;
   color:#808080;  
} 


.billing-div {
  margin-top: 225px !important;
}

.billing-div-2 {
  margin-top: 100px !important;
}

.billing-div-3 {
  margin-top: 50px !important;
}

.billing-management-header {
  color:#E04F60 !important; 
}

.billing-management-header-teams {
  color:#E04F60 !important; 
}

.billing-management-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.fa-twitter-square {
  color: #E04F60
}

.fa-linkedin-square {
  color: #E04F60
}

.fa-envelope-square {
  color: #E04F60
}

.signUpButton {
    
    margin-top: 20px !important;
    height: 60px !important;
    font-size: 100%;
    letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  
  
  transition: all 0.2s;
    border-color: transparent !important;
    background-color:  #E04F60 !important;    
}

.signUpButton2 {
    
  margin-top: 10px !important;
  height: 60px !important;
  width: 100% !important;
  font-size: 100% !important;
  letter-spacing: .1rem;
  font-weight: bold !important;
  padding: 1rem;

transition: all 0.2s;
  border-color: transparent !important;
  background-color:  #E04F60  !important;       
}

.quizButton {
    
  margin-top: 10px !important;
  height: 60px !important;
  width: 100% !important;
  font-size: 100% !important;
  letter-spacing: .1rem;
  font-weight: bold !important;
  padding: 1rem;

transition: all 0.2s;
  border-color: #E04F60  !important;
  color: #E04F60  !important;
  background-color:  white  !important;       
}


.readMoreButtonDiv {
  text-align: center;
}



.privacy-header {
  color:  #E04F60 !important;
}

.readMoreButton {
    
  margin-top: 10px !important;
  height: 60px !important;
  width: 200px !important;
  font-size: 100% !important;
  letter-spacing: .1rem;
  font-weight: bold !important;
  padding: 1rem;

transition: all 0.2s;
  border-color: transparent !important;
  background-color:  #E04F60  !important;       
}

/* section.cta {
  background: #E04F60;
  background: linear-gradient(to right, #570f18, #fbe9eb);
} */

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 2rem;
  margin: 0;
}

.pricing .card-price-old {
  font-size: 1.5rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 1rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
    border-color: transparent !important;
    background-color:  #E04F60 !important; 
}

.pricing .btn-first {
  font-size: 90%;
  color: black;
  border-radius: 2rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
    border-color: #E04F60 !important;
    background-color: #E04F60 !important; 
}

.pricing .btn-second {
  font-size: 90%;
  color: black;
  border-radius: 2rem;
  letter-spacing: .1rem;
  padding: 1rem;
  transition: all 0.2s;
    border-color: #E04F60 !important;
    background-color: transparent !important; 
}

.btn-switch {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
    border-color: transparent !important;
    background-color:  #E04F60 !important; 
}

.btn-primary-tpb {
  background-color: #E04F60 !important; /* Primary color */
  color: white;
  border: 1px solid #E04F60 !important;
}

.btn-outline-primary-tpb {
  background-color: transparent;
  color: #E04F60 !important;
  border: 1px solid #E04F60 !important;
}




}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
  
}  

@media (max-width: 100px) {
    .jumbotron-fluid {
    background-color: transparent !important;
    margin-top: 40px !important;
    
      
}

.sign-up {
    
    background-color:  transparent !important;  
    border-color: gray !important;
    color:#E04F60 !important; 
}

.Navbar {
    background-color: #FCEFF1 !important;
}

.dashboard {
  margin-top: 100px !important;
}

.signUpForm {
    height: 60px !important;
}
  .signUpButton {
    height: 60px !important;
    
    border-color: transparent !important;
    background-color:  #E04F60 !important;    
}  
    
}

@media (min-width: 1050px) {
  .landing-header {
    font-size: 2.7vw !important;
  }
  .landing-header-trivia {
    font-size: 2.5vw !important;
  }
  
}  

@media (max-width: 450px) {
  .landing-header {
    font-size: 8.9vw !important;
  }
  .landing-header-trivia {
    font-size: 8.9vw !important;
  }
} 


@media (max-width: 767px) {
  .signUpButton2 {
    
    margin-top: 10px !important;
    height: 60px !important;
    width: 100% !important;
    font-size: 90% !important;
    letter-spacing: .1rem;
    font-weight: bold !important;
    padding: 1rem;
  
  transition: all 0.2s;
    border-color: transparent !important;
    background-color:  #E04F60  !important;       
  }

  .hide-text {
    display: none;
  }
}



@media (min-width: 768px) and (max-width: 992px) {
  .signUpButton2 {
    
    margin-top: 10px !important;
    height: 60px !important;
    width: 100% !important;
    font-size: 90% !important;
    letter-spacing: .1rem;
    font-weight: bold !important;
    padding: 1rem;
  
  transition: all 0.2s;
    border-color: transparent !important;
    background-color:  #E04F60  !important;       
  }

  .hide-text {
    display: none;
  }
}

